<template>
  <el-dialog
    :show-close="true"
    :visible="dialogVisible"
    top="8vh"
    width="800px"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div slot="title" class="header-title">
      <span class="icon-box"></span>
      <span style="color: #000;font-size:16px"
        >催单详情页（催单编号：{{ detailedData.reminderNumber }}）</span
      >
    </div>
    <div v-loading="dialogLoading">
      <el-row style="padding: 0 25px">
        <el-row :gutter="20" style="margin-bottom: 18px">
          <el-col :span="8" style="display: flex">
            <span class="description">衣物号：</span>
            <el-input v-model="detailedData.outClothesNum" disabled />
          </el-col>
          <el-col :span="8" style="display: flex">
            <span class="description">衣物名称：</span>
            <el-input v-model="detailedData.clothesName" disabled />
          </el-col>
          <el-col :span="8" style="display: flex">
            <span class="description">衣物颜色：</span>
            <el-input v-model="detailedData.clothesColor" disabled />
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-bottom: 18px">
          <el-col :span="8" style="display: flex">
            <span class="description">衣物状态：</span>
            <el-input v-model="detailedData.clothesStatusDesc" disabled />
          </el-col>
          <el-col :span="8" style="display: flex">
            <span class="description">催单状态：</span>
            <el-input v-model="detailedData.statusDesc" disabled />
          </el-col>
          <el-col :span="8" style="display: flex">
            <span class="description">催单次数：</span>
            <el-input v-model="detailedData.reminderCount" disabled />
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-bottom: 10px">
          <el-col :span="8">
            <el-row style="margin-bottom: 18px; display: flex">
              <el-col class="description" style="width: 83px"
                >售后状态：</el-col
              >
              <el-col :span="18">
                <el-input
                  v-model="detailedData.afterSaleStatusDesc"
                  class="text1"
                  disabled
                  style="width: 100%"
                />
              </el-col>
            </el-row>
            <el-row style="margin-bottom: 18px; display: flex">
              <el-col class="description" style="width: 83px">
                售后编号：
              </el-col>
              <el-col :span="18">
                <el-input
                  v-model="detailedData.afterSaleNumber"
                  class="text1"
                  disabled
                  style="width: 100%"
                />
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="16" style="display: flex">
            <span class="description" style="width: 86px">反馈内容：</span>
            <el-input
              v-model="detailedData.storeExplain"
              class="feedback-content"
              disabled
              :rows="4"
              resize="none"
              type="textarea"
            />
          </el-col>
        </el-row>
        <el-row :gutter="20" style="margin-bottom: 10px">
          <el-col :span="8">
            <el-row style="margin-bottom: 18px; display: flex">
              <el-col class="description" style="width:130px;margin-left:-40px"
                >预计出厂时间：</el-col
              >
              <el-col :span="18">
                <el-input
                  v-model="detailedData.estimateTimeFormat"
                  class="text1"
                  disabled
                  style="width: 100%"
                />
              </el-col>
            </el-row>
          </el-col>
          <el-row style="margin-bottom: 18px; display: flex">
            <span class="description" style="width: 86px">图片：</span>
            <el-image
              v-for="(item, index) in detailedData.clothesImageList"
              v-show="index < 3"
              :key="index"
              :preview-src-list="detailedData.clothesImageList"
              :src="item"
              style="width: 40px; height: 40px; padding-right: 5px"
            />
            <span
              v-if="
                detailedData.clothesImageList
                  ? detailedData.clothesImageList.length > 3
                  : false
              "
              class="imgs"
            >
              +{{ detailedData.clothesImageList.length }}
            </span>
          </el-row>
        </el-row>
        <el-divider />
      </el-row>
      <!-- 记录 -->
      <div class="footer">
        <el-row
          style="padding-bottom: 10px"
          v-for="(item, index) in detailedData.reminderFrequencyList"
          :key="index"
        >
          <span class="icon-box"></span>
          <span style="color: #000; font-size: 16px"
            >第{{ item.frequency }}次催单记录</span
          >
          <div
            class="trackProgress"
            v-for="(items, indexs) in item.operateList"
            :key="indexs"
          >
            <div class="noRecord" v-if="noRecord">暂无跟进记录 ~</div>
            <el-row style="padding: 10px 16px 0px 16px">
              <el-col>
                <i
                  class="el-icon-success icon"
                  :style="{
                    color:
                      items.statusDesc == '待处理'
                        ? '#B30D0D'
                        : items.statusDesc == '处理中'
                        ? '#FE8800'
                        : '#1D8E33',
                  }"
                ></i>
                <span style="padding-right: 10px">{{ items.statusDesc }}</span>
                <span>{{ items.createdTime }}</span>
              </el-col>
              <el-col class="col-item" v-if="items.reminderReply">
                <span>{{ items.reminderReply }}</span>
              </el-col>
              <el-col class="col-item" v-if="items.estimateTime">
                <span style="padding-right:20px">预计出厂时间：</span>
                <span>{{ items.estimateTime }}</span>
              </el-col>
              <el-col class="col-item" v-if="items.reminderSaleReply">
                <span>{{ items.reminderSaleReply }}</span>
              </el-col>
              <!-- 2023.1.22移除 -->
<!--              <el-col class="col-item" v-if="items.handleSolution">-->
<!--                <span style="padding-right:20px">售后类别：</span>-->
<!--                <span>{{ items.handleSolution }}</span>-->
<!--              </el-col>-->
<!--              <el-col class="col-item" v-if="items.afterSaleTypeDesc">-->
<!--                <span style="padding-right:20px">售后类型：</span>-->
<!--                <span>{{ items.afterSaleTypeDesc }}</span>-->
<!--              </el-col>-->
              <el-col class="col-item" v-if="items.afterSaleId">
                <span style="padding-right:20px">售后编号：</span>
                <span>{{ items.afterSaleId }}</span>
              </el-col>
              <el-col
                style=" display: flex;padding: 5px 19px 0"
                v-if="items.newCredentialList != null"
              >
                <span style="line-height:32px">售后图片：</span>
                <el-image
                  v-for="(item, index) in items.newCredentialList"
                  v-show="index < 3"
                  :key="index"
                  :preview-src-list="items.newCredentialList"
                  :src="item"
                  style="width: 40px; height: 40px; padding-right: 5px"
                />
                <span
                  v-if="
                    items.newCredentialList
                      ? items.newCredentialList.length > 3
                      : false
                  "
                  class="imgs"
                >
                  +{{ items.newCredentialList.length }}
                </span>
              </el-col>
            </el-row>
            <el-divider/>
          </div>
        </el-row>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "",
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    detailedData: {
      type: Object,
      default: () => {},
    },
    dialogLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      noRecord: false,
    };
  },
  watch: {
    detailedData(val) {
      console.log(val.reminderFrequencyList, "详情内容");
      if (val.reminderFrequencyList.length > 0) {
        this.noRecord = false;
      } else {
        this.noRecord = true;
      }
    },
  },
  methods: {
    handleClose() {
      this.$emit("closeDialog");
    },
  },
};
</script>

<style scoped lang="scss">
.icon-box {
  display: inline-block;
  background: #3370FF;
  width: 4px;
  height: 18px;
  border-radius: 2px;
  vertical-align: bottom;
  margin-right: 5px;
}
.details-form {
  padding: 20px;
  ::v-deep .el-divider--horizontal {
    margin: 0;
  }
}
.description {
  text-align: right;
  width: 105px;
  line-height: 32px;
}
.text1 {
  display: inline-block;
  vertical-align: bottom;
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
::v-deep .el-dialog__body {
  padding: 5px 20px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}
::v-deep .el-form--inline .el-form-item__label {
  padding-left: 10px;
}
.imgs {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background-color: #e8e8e8;
  cursor: pointer;
}
.icon {
  margin-left: -17px;
  padding: 0 10px;
  font-size: 16px;
}
.noRecord {
  height: 100px;
  text-align: center;
}
.col-item {
  padding: 10px 19px 0;
}
.footer {
  overflow: auto;
  .trackProgress{
    font-size: 14px;
  }
}
::v-deep .el-divider--horizontal{
  margin: 15px 0 10px 0;
}
</style>
