<template>
  <div class="reminders content-index">
    <div class="header">
      <div class="reminders-top">
        <el-page-header
            style="width: 100%;padding:10px"
            @back="$router.go(-1)"
            content="催单列表"
        ></el-page-header>
        <div class="query-item">
          <el-input
            v-model.trim="pageData.outClothesNum"
            clearable
            placeholder="请输入衣物号"
          />
          <el-button
            type="primary"
            style="margin-left:10px"
            @click="queryClothingNumber"
            >查询</el-button
          >
        </div>
      </div>
      <div class="reminder-item">
        <el-input
          style="padding:5px"
          v-model.trim="clothingName"
          @keyup.enter.native="complaint"
          placeholder="请输入/扫衣物号码"
          ref="query"
        ></el-input>
        <el-button
          type="primary"
          icon="el-icon-plus"
          class="btn-add"
          size="small"
          @click="complaint"
        >
          我要催单</el-button
        >
      </div>
    </div>
    <el-tabs
      v-model="stateValue"
      type="card"
      @tab-click="handleClick"
      v-loading="loading"
    >
      <el-tab-pane
        :label="item.label"
        :name="item.name"
        v-for="(item, index) in tabPane"
        :key="index"
      >
        <div slot="label">
          <el-badge
            :value="item.notViewed"
            class="item"
            :max="99"
            v-if="item.notViewed > 0"
          >
            <span>{{ item.label }}</span>
            <span v-show="item.quantity != ''">（{{ item.quantity }}）</span>
          </el-badge>
          <div v-else>
            <span>{{ item.label }}</span>
            <span v-show="item.quantity != ''">（{{ item.quantity }}）</span>
          </div>
        </div>
      </el-tab-pane>
      <Table
        :border-bool="true"
        :button-type="buttonList"
        :current-value="pageData.current"
        :header-style="headerStyle"
        :orientation="'center'"
        :serial-number="true"
        :table-data="tableData"
        :title-list="titleListData"
        :total="total"
        :height="'62vh'"
        @operationClick="operationClick"
        @handleCurrentChange="handleCurrentChange"
      />
    </el-tabs>
    <reminderPage
      :dialogVisible="dialogVisible"
      :addLoading="addLoading"
      :springFrame="springFrame"
      @closeDialog="closeDialog"
      :reminderData="reminderData"
      @refreshReminderList="refreshReminderList"
      @appealDialogVisible="appealDialogVisible"
      @reminderSucceeded="reminderSucceeded"
      :reminderList="reminderList"
      :reminderDialogLoading="reminderDialogLoading"
    ></reminderPage>
    <Details
      :dialogVisible="dialogDetails"
      @closeDialog="closeDialog"
      :detailedData="detailedData"
      :dialogLoading="dialogLoading"
    ></Details>
  </div>
</template>

<script>
import Table from "./component/table";
import reminderPage from "@/components/reminderPage";
import Details from "./component/details";
import {
  remindersPage,
  queryReminder,
  reminderStatus,
  reminderDetail,
} from "@/api/factory/reminderOrder";
export default {
  name: "complaint",
  components: {
    Table,
    reminderPage,
    Details,
  },
  data() {
    return {
      total: 0,
      pageData: {
        current: 1,
        size: 10,
        outClothesNum: "",
        reminderStatus: "",
      },
      stateValue: "10",
      detailedData: {},
      tableData: [],
      titleListData: [
        {
          prop: "reminderNumber",
          label: "催单编号",
          width: "150",
        },
        {
          prop: "outClothesNum",
          label: "衣物号",
          width: "150",
        },
        {
          prop: "clothesStatusDesc",
          label: "衣物状态",
          width: "200",
        },
        {
          prop: "reminderAppealDesc",
          label: "催单诉求",
          width: "200",
        },
        {
          prop: "reminderCount",
          label: "催单次数",
          width: "200",
        },
        {
          prop: "createdTime",
          label: "提交时间",
          width: "180",
        },
        {
          prop: "estimateTime",
          label: "预计出厂时间",
          width: "200",
        },
      ],
      tabPane: [
        {
          label: "全部",
          name: "10",
          notViewed: "",
          quantity: "",
        },
        {
          label: "待处理",
          name: "1",
          notViewed: "",
          quantity: "",
        },
        {
          label: "处理中",
          name: "2",
          notViewed: "",
          quantity: "",
        },
        {
          label: "已完结",
          name: "3",
          notViewed: "",
          quantity: "",
        },
      ],
      buttonList: [
        {
          name: "再次催单",
          size: "medium",
          type: "text",
          code: "reminder",
        },
        {
          name: "查看详情",
          size: "medium",
          type: "text",
          code: "details",
        },
      ],
      headerStyle: {
        background: "#FFF",
      },
      title: "",
      dialogVisible: false,
      dialogDetails: false,
      loading: false,
      addLoading: false,
      dialogLoading: false,
      reminderDialogLoading: false,
      clothingName: "",
      reminderData: {},
      springFrame: false,
      reminderList: {},
    };
  },
  created() {
    this.remindersPage();
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.query.focus();
    });
  },
  methods: {
    // tabs切换
    handleClick(val) {
      this.pageData.outClothesNum = "";
      if (val.name == 10) {
        this.pageData.reminderStatus = "";
      } else {
        this.pageData.reminderStatus = val.name;
      }
      this.pageData.current = 1;
      this.remindersPage();
    },
    //催单数据
    remindersPage() {
      this.loading = true;
      remindersPage(this.pageData).then((res) => {
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
        this.loading = false;
        this.reminderStatus(this.pageData.outClothesNum);
      });
    },
    // 分页
    handleCurrentChange(value) {
      this.pageData.current = value;
      this.remindersPage();
    },
    //查询可催单的订单
    queryReminder() {
      this.reminderDialogLoading = true;
      queryReminder(this.clothingName)
        .then((res) => {
          if (res.data.code == 0) {
            this.reminderData = res.data.data;
            this.reminderDialogLoading = false;
          } else {
            this.reminderDialogLoading = false;
          }
        })
        .catch(() => {
          this.reminderDialogLoading = false;
        });
    },
    refreshReminderList() {
      this.queryReminder();
      this.remindersPage();
    },
    complaint() {
      if (this.clothingName == "") {
        this.$message.error("请输入可催单的衣物号");
        return;
      }
      this.title = "请填写";
      this.dialogVisible = true;
      this.queryReminder();
    },
    //衣物号查询
    queryClothingNumber() {
      this.remindersPage();
      this.reminderStatus(this.pageData.outClothesNum);
    },
    //表格按钮
    operationClick({ row, code }) {
      if (code == "details") {
        this.title = "详情";
        this.dialogDetails = true;
        this.dialogLoading = true;
        reminderDetail(row.reminderId)
          .then((res) => {
            if (res.data.code == 0) {
              this.detailedData = res.data.data;
              this.dialogLoading = false;
            } else {
              this.dialogLoading = false;
            }
          })
          .catch(() => {
            this.dialogLoading = false;
          });
        if (row.storeViewStatus == 0) {
          this.remindersPage();
        }
      } else if (code == "reminder") {
        this.springFrame = true;
        this.reminderList = row;
      }
    },
    appealDialogVisible() {
      this.springFrame = false;
      this.reminderList = {};
    },
    //再次催单成功
    reminderSucceeded() {
      this.springFrame = false;
      this.reminderList = {};
      this.remindersPage();
    },
    closeDialog(val) {
      if (val == 1) {
        this.dialogVisible = false;
      } else {
        this.dialogDetails = false;
      }
    },
    reminderStatus(val) {
      let params = {
        outClothesNum: val,
      };
      reminderStatus(params).then((res) => {
        let data = res.data.data;
        this.tabPane = this.tabPane.map((item, index) => {
          if (index == 0 && data.allCount != 0) {
            item.quantity = data.allCount;
            item.notViewed = data.noViewCount;
          } else if (index == 1 && data.pendingCount != 0) {
            item.quantity = data.pendingCount;
            item.notViewed = data.pendingNoViewCount;
          } else if (index == 2 && data.processingCount != 0) {
            item.quantity = data.processingCount;
            item.notViewed = data.processingNoViewCount;
          } else if (index == 3 && data.processedCount != 0) {
            item.quantity = data.processedCount;
            item.notViewed = data.processedNoViewCount;
          } else {
            item.quantity = "";
            item.notViewed = "";
          }
          return item;
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
.reminders {
  overflow: auto;
  .header {
    padding: 17.5px 20px;
    overflow: hidden;

    > .reminders-top {
      float: left;
      color: #333;
      text-align: left;
      font-size: 20px;
      font-family: PingFang-SC-Bold;
      .query-item {
        display: flex;
        padding: 10px 0;
      }
    }

    > .reminder-item {
      display: flex;
      border-radius: 5px;
      background: #b30d0d;
      border-color: #b30d0d;
      float: right;
    }
    .btn-add {
      background: #b30d0d;
      border-color: #b30d0d;
      float: right;
    }
  }
}
.icon-box {
  position: absolute;
  width: 3px;
  height: 22px;
  left: 24px;
  background: #3894ff;
}
.el-tabs {
  margin: 0px 20px 0;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #e4e7ed;
}
::v-deep .el-badge__content.is-fixed {
  top: 10px;
  right: 10px;
}
</style>
